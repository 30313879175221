.about{
    color: #0a290a;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
   }
.heading{
    color: #0a290a;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -10px;
    font-weight: bold;
   }