

/*
 * Preparing stage
 */




/*
 * Start
 */
.glassBox, .glassBox * {
  box-sizing: border-box;
  transition: 800ms;
}

.glassBox {
  width: 100%;
  height: 400px;
  max-width: 100%;
  border-radius: 5px;
   background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255)); 
  backdrop-filter: blur(100px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* border-right-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1); */
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  
  
  
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.glassBox__imgBox{
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
  
}

.glassBox__imgBox img {
  /* display: block; */
  opacity:0.7;
  /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
  
}

.glassBox__title {
  text-align: center;
  margin-top: 20px;
  /* color: #FFF; */
  font-size: 20px;
  font-weight:800;
  
  font-family: "Lato";
}

.glassBox__content {
  position: absolute;
  right: 15px;
  bottom: 15px;
  left: 15px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-family: "Lato";
  letter-spacing: .1em;
  opacity: 0;
}

.glassBox:hover .glassBox__imgBox {
  transform: translateY(-50px);
}

.glassBox:hover .glassBox__imgBox img {
  transform: translate(-20px, -40px) rotate(-15deg) scale(1.4);
}

.glassBox:hover .glassBox__title {
  /* margin-top: 40px; */
}
