.theme5{
    color: #EBD8B2;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
   }
.theme5heading{
    color: #EBD8B2;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -10px;
    font-weight: bold;
   }
.theme1{
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
   }
.theme1heading{
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -10px;
    font-weight: bold;
   }
.theme3{
    color: #000;
     text-align: justify;
     font-size: 15px;
     font-family: 'Poppins', sans-serif;
     margin-left: 10;
   }
.theme3heading{
    color: #000;
     text-align: center;
     font-size: 25px;
     font-family: 'Poppins', sans-serif;
     margin-bottom: -10px;
     font-weight: bold;
   }
.theme2{
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
   }
.theme2heading{
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -10px;
    font-weight: bold;
   }

   .theme4{
    color: #0a290a;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
   }
.theme4heading{
    color: #0a290a;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -10px;
    font-weight: bold;
   }