#img1 {
    width:100vw;
    height:100vh;
    cursor: pointer;
    transform-origin: center center 0px;
    transition: all 0.5s ease-out;
    animation-name: animate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transform: perspective(1000px) rotateX(35deg) rotateY(4deg) rotateZ(-40deg);
    z-index: 1;
  }
  #img1:hover {
    animation: unset;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }