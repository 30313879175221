/* VideoBackground.css */

.video-background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      overflow-x: hidden;
    }
  
    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
    }
  }
  