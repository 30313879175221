

/*
 * Preparing stage
 */




/*
 * Start
 */
 .review, .review * {
    box-sizing: border-box;
    transition: 800ms;
  }
  
  .review {
    width: 100%;
   
    max-width: 100%;
    border-radius: 5px;
     
    backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* border-right-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); */
    /* box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1); */
    
    
    
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .review__imgBox{
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    position: relative;
    /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
    
  }
  
  .review__imgBox img {
    /* display: block; */
    opacity:0.5;
    /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
    
  }
  
  .review__title {
    text-align: center;
    margin-bottom: 10px;
    /* color: #FFF; */
    font-size: 20px;
    font-weight:800;
    
    font-family: "Lato";
    position: absolute;
  }
  
  .review__content {
    position: absolute;
    right: 15px;
    bottom: 15px;
    left: 15px;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    font-family: "Lato";
    letter-spacing: .1em;
    opacity: 0;
    
  }
  
  /* .review:hover .review__imgBox {
    transform: translateY(-30px);
  }
  
  .review:hover .review__imgBox img {
    transform: translate(-20px, -40px) rotate(-10deg) scale(1.2);
  } */
  .review__imgBox img:hover {
    transform: scale(1.1); /* Adjust the scale factor as needed */
    transition: transform 0.5s ease; /* Add a smooth transition for the effect */
  }
  
  .review:hover .review__title {
    /* margin-top: 40px; */
  }
  