.centered-box {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px; 
  height: auto; 
  background-color: #f0f0f0; 
  padding: 28px;
}



