.font{
    font-family: 'Aoboshi One', serif;
    margin-top: -5px;   
    margin-bottom: -5px;   
}

.font1{
    font-family: 'Aoboshi One', serif;
    display:flex;
    justify-content: center; 
    font-size:32px;
    margin-bottom:20px;
    margin-top: 20px;
    color: black;
}
.welcome{
    font-family: 'Aoboshi One', serif;
    display:flex;
    justify-content: center; 
    font-size:60px;
    margin-bottom:20px;
    margin-top: 20px;
   
    color: black;
}
.welcome1{
    font-family: 'Aoboshi One', serif;
    display:flex;
    justify-content: center; 
    font-size:60px;
    margin-bottom:20px;
    margin-top: 20px;
   
    color: white;
}

.activate{
    font-family: 'Aoboshi One', serif;
    display:flex;
    justify-content: center;
    background-color: #F3B419;
    border-radius: 2px;
    padding-bottom:18px;
    color:'black';
    font-size: 56px;
    text-align: center;
}
.activate1{
    font-family: 'Aoboshi One', serif;
    display:flex;
    justify-content: center;
    background-color: #F3B419;
    border-radius: 2px;
    padding-bottom:18px;
    color:'black';
    font-size: 34px;
    text-align: center;
}