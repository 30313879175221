

/*
 * Preparing stage
 */




/*
 * Start
 */
 .three, .three * {
    box-sizing: border-box;
    transition: 800ms;
  }
  
  .three {
   
    height: 400px;
    /* max-width: 100%; */
    border-radius: 5px;
     
    backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* border-right-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); */
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    margin-left: 15px;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .three__imgBox{
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    position: relative;
    /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
    
  }
  
  .three__imgBox img {
    /* display: block; */
    opacity:0.7;
    /* background: linear-gradient(to right,rgba(43,112,67,255),rgba(57,178,145,255));  */
    
  }
  
  .three__title {
    text-align: center;
    margin-top: 20px;
    color: #FFF;
    font-size: 25px;
    font-weight:bolder;
    position: absolute;
    font-family: "Lato";
  }
  
  .three__content {
    position: absolute;
    right: 30px;
    bottom: -25px;
   
    text-align: center;
    color: #FFF;
    font-size: 15px;
    font-family: "Lato";
    letter-spacing: .1em;
    
  }
  
  .three:hover .three__imgBox {
    transform: translateY(-50px);
  }
  
  .three:hover .three__imgBox img {
    transform: translate(-20px, -40px) rotate(-15deg) scale(1.4);
  }
  
  .three:hover .three__title {
    /* margin-top: 40px; */
  }